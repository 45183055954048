import Cookies from 'js-cookie'
import SsoProvider from '@/models/SsoProvider'
import Api from '@/services/api'

export default {
  namespaced: true,
  state: {
    ssoDetail: null,
    ssoProviderDetails: null,
  },

  mutations: {
    setSsoProviderDetails(state, ssoProvider) {
      if (ssoProvider instanceof SsoProvider) {
        state.ssoProviderDetails = ssoProvider
      } else {
        state.ssoProviderDetails = new SsoProvider(ssoProvider)
      }
    },

    setSsoDetail(state, detail) {
      state.ssoDetail = detail
    },
  },

  actions: {
    init({ commit }) {
      const token = Cookies.getJSON('AUTH_TOKEN')
      commit('setToken', token)
    },

    async getSsoProviderDetails({ commit }) {
      const { data } = await Api.get('sso-provider-settings/azure')
      commit('setSsoProviderDetails', data.data)
    },

    async createSsoProvider({ commit }, formData) {
      const { data } = await Api.post('sso-provider-settings', formData)
      commit('setSsoProviderDetails', data.data)
    },

    async updateSsoProvider({ commit }, formData) {
      const provider = formData.get('provider')
      const { data } = await Api.post(
        `sso-provider-settings/${provider}?_method=PUT`,
        formData
      )
      commit('setSsoProviderDetails', data.data)
    },

    async checkSsoEnable({ commit }) {
      const { data } = await Api.get('sso-provider-settings/check-enable/azure')
      commit('setSsoDetail', data)
    },
  },
}
