import Event from '@/models/Event'
import Api from '@/services/api'
import { get, sumBy } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
    eventDetails: null,
    totalEvents: 0,
    upcomingEvents: [],
    totalUpcomingEvents: 0,
    eventsGrouped: [],
  },

  mutations: {
    setEventList(state, events) {
      state.list = events
    },

    clearEventList(state) {
      state.list = []
      state.totalEvents = 0
      state.upcomingEvents = []
      state.totalUpcomingEvents = 0
    },

    setEventListMeta(state, meta) {
      state.listMeta = meta
    },

    setEventDetails(state, event) {
      if (state.eventDetails) {
        if (!event.banner) event.banner = state.eventDetails.banner
        if (!event.images) event.images = state.eventDetails.images
      }

      if (event instanceof Event) {
        state.eventDetails = event
      } else {
        state.eventDetails = new Event(event)
      }
    },

    setEventImage(state, payload) {
      if (state.eventDetails) {
        state.eventDetails[payload.image_name] =
          payload.images && payload.image_name === 'banner'
            ? payload.images[0]
            : [...state.eventDetails.images, ...payload.images]
      }
    },

    clearEventDetails(state) {
      state.eventDetails = null
    },

    setEventTotals(state, total) {
      state.totalEvents = total
    },

    setEventsUpcoming(state, events) {
      state.upcomingEvents = events
    },

    setTotalEventsUpcoming(state, total) {
      state.totalUpcomingEvents = total
    },

    setEventsGroupedList(state, eventsGrouped) {
      state.eventsGrouped = eventsGrouped
    },
  },

  actions: {
    async getEvents({ commit, rootGetters }, params) {
      const query = Event.page(params.page || 1).limit(
        params.itemsPerPage || 10
      )
      query.params({ paginate: true })

      if (params.sort) {
        query.orderBy(params.sort)
      }

      if (params.search) {
        query.where('search', encodeURIComponent(params.search))
      }

      if (params.upcoming) {
        query.where('past_events', 'exclude')
      }

      if (params.past) {
        query.where('past_events', 'only')
      }

      let filteredBuilding = rootGetters['filter/buildingId']
      if (filteredBuilding) {
        query.where('building_id', filteredBuilding)
      }

      if (params.past_events) {
        query.where('past_events', params.past_events)
      }

      query.include(['building', 'category'])

      const res = await query.get()
      commit('setEventList', res.data)
      commit('setEventListMeta', res.meta)
      if (get(res, 'meta.total', 0) > 0) {
        commit('setEventTotals', get(res, 'meta.total', 0))
      }
    },

    async getEventsGroupedCategories({ commit, rootGetters }, params) {
      const query = Event.page(params.page || 1)

      if (params.sort) {
        query.orderBy(params.sort)
      }

      if (params.search) {
        query.where('search', encodeURIComponent(params.search))
      }

      if (params.upcoming) {
        query.where('past_events', 'exclude')
      }

      if (params.past) {
        query.where('past_events', 'only')
      }

      let filteredBuilding = rootGetters['filter/buildingId']
      if (filteredBuilding) {
        query.where('building_id', filteredBuilding)
      }

      if (params.past_events) {
        query.where('past_events', params.past_events)
      }

      query.include(['building', 'category'])

      query.params({ grouped_by: params.grouped_by })

      const res = await query.get()
      commit('setEventsGroupedList', res)
      commit('setEventTotals', sumBy(res, 'count'))
    },

    async getEventsGroupedTitle({ commit, rootGetters }, params) {
      const query = Event.page(params.page || 1).limit(params.itemsPerPage || 5)
      query.params({ paginate: true })

      if (params.sort) {
        query.orderBy(params.sort)
      }

      if (params.search) {
        query.where('search', encodeURIComponent(params.search))
      }

      if (params.upcoming) {
        query.where('past_events', 'exclude')
      }

      if (params.past) {
        query.where('past_events', 'only')
      }

      let filteredBuilding = rootGetters['filter/buildingId']
      if (filteredBuilding) {
        query.where('building_id', filteredBuilding)
      }

      if (params.past_events) {
        query.where('past_events', params.past_events)
      }

      query.where('title', params.title)

      query.include(['building', 'category'])

      return query.get()
    },

    getEventByIndex({ state }, index) {
      return state.list && state.list.length > index ? state.list[index] : null
    },

    async getEventDetails({ commit }, id) {
      const { data } = await Api.get(`events/${id}`)
      commit('setEventDetails', data.data)
    },

    async createEvent({ commit }, formData) {
      const { data } = await Api.post(`events`, formData)
      commit('setEventDetails', data.data)
    },

    async updateEvent({ commit }, formData) {
      const { data } = await Api.put(`events/${formData.id}`, formData)
      commit('setEventDetails', data.data)
    },

    async uploadEventImage({ commit }, formData) {
      const { data } = await Api.post(
        `events/${formData.get('id')}/images`,
        formData
      )

      commit('setEventImage', {
        images: data.data,
        image_name: formData.get('image_name'),
      })
    },

    async deleteEventImage({ commit }, formData) {
      await Api.delete(`media/${formData.id}`, formData)

      if (formData.image_name === 'banner') {
        commit('setEventImage', {
          images: [null],
          image_name: formData.image_name,
        })
      }
    },

    async duplicateEvent({ commit }, formData) {
      const { data } = await Api.post(`events/${formData.id}/duplicate`)
      return data.data
    },

    async getUpcomingEvent({ commit, rootGetters }, params) {
      const queryParams = new URLSearchParams({
        page: params.page || 1,
        sort: params.sort || '',
        include: ['building', 'category', 'eventConfirmations', 'banner'],
        limit: params.itemsPerPage || 5,
      })

      // Add building filter if not nil
      let filteredBuilding = rootGetters['filter/buildingId']
      if (filteredBuilding) {
        queryParams.append('filter[building_id]=', filteredBuilding)
      }

      const url = `events/upcoming?${queryParams.toString()}`

      const { data } = await Api.get(url)

      const upcomingEvent = data.data.map((booking) => {
        const date = new Date(booking.datetime_start)
        const hours = date.getHours() % 12 || 12 // Convert 0 to 12-hour format
        const period = date.getHours() >= 12 ? 'PM' : 'AM'
        const formattedDate = `${date.getDate()}/${
          date.getMonth() + 1
        }/${date.getFullYear()} ${hours}:${('0' + date.getMinutes()).slice(
          -2
        )} ${period}`
        let rsvp = ''
        const eventConfirmations = booking.attendees
        if (booking.rsvp_limit === 0) {
          rsvp = eventConfirmations
        } else {
          rsvp = `${eventConfirmations}/${booking.rsvp_limit}`
        }
        return {
          ...booking,
          datetime_start: formattedDate,
          rsvp,
        }
      })

      commit('setEventsUpcoming', upcomingEvent)

      if (get(data, 'meta.total', 0) > 0) {
        commit('setTotalEventsUpcoming', get(data, 'meta.total', 0))
      }
    },
  },
}
